<template>
  <edit-periodo-action-plan />
</template>
<script>
import EditPeriodoActionPlan from "@/components/action-plans/EditPeriodoActionPlan/index.vue";

export default {
  name: "EditActionPlanPage",
  components: { EditPeriodoActionPlan },
};
</script>
